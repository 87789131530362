import React, { useState } from "react";

import TaskList from "./TaskList";
import FormTodo from "./FormTodo";
import { useEffect } from "react";

const Container = () => {
  const [list, setList] = useState([]);

  useEffect(() => {
    fetch("api/todo/all")
      .then((data) => data.json())
      .then((response) => setList(response));
  }, []);

  const handleAddItem = (addItem) => {
    fetch("api/todo", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(addItem),
    })
      .then((data) => data.json())
      .then((response) => setList([...list, response]));
  };

  return (
    <div>
      <FormTodo handleAddItem={handleAddItem} />
      <TaskList list={list} setList={setList} />
    </div>
  );
};

export default Container;
