import React from "react";
import Checkbox from "./Checkbox";

const TaskList = (props) => {
  const { list, setList } = props;

  const onChangeStatus = (e) => {
    const { name: taskId, checked } = e.target;

    const task = list.filter((item) => item.id === parseInt(taskId))[0];

    task.isDone = checked;

    fetch("api/todo", {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(task),
    })
      .then((data) => data.json())
      .then((response) => {
        const updateList = list.map((item) => ({
          ...item,
          isDone: item.id === response.id ? response.isDone : item.isDone,
          name: item.id === response.id ? response.name : item.name,
        }));
        setList(updateList);
      });
  };

  const onClickRemoveItem = (e) => {
    fetch("api/todo/deletealldone", {
      method: "DELETE",
      headers: { "Content-Type": "application/json" },
    })
      .then((_) => {
        const updateList = list.filter((item) => !item.isDone);
        setList([...updateList]);
      });
  };

  const chk = list.map((item) => (
    <Checkbox key={item.id} data={item} onChange={onChangeStatus} />
  ));
  return (
    <div className="todo-list">
      {list.length ? chk : "No tasks"}
      {list.length ? (
        <p>
          <button className="button blue" onClick={onClickRemoveItem}>
            Delete all done
          </button>
        </p>
      ) : null}
    </div>
  );
};

export default TaskList;
